/* tslint:disable */
/* eslint-disable */
/**
 * Parallels Data
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ColumnRecognizeResult } from './ColumnRecognizeResult';
import {
    ColumnRecognizeResultFromJSON,
    ColumnRecognizeResultFromJSONTyped,
    ColumnRecognizeResultToJSON,
} from './ColumnRecognizeResult';
import type { ProjectListMetadata } from './ProjectListMetadata';
import {
    ProjectListMetadataFromJSON,
    ProjectListMetadataFromJSONTyped,
    ProjectListMetadataToJSON,
} from './ProjectListMetadata';
import type { ProjectStatusObject } from './ProjectStatusObject';
import {
    ProjectStatusObjectFromJSON,
    ProjectStatusObjectFromJSONTyped,
    ProjectStatusObjectToJSON,
} from './ProjectStatusObject';
import type { ProjectSupportDataStatus } from './ProjectSupportDataStatus';
import {
    ProjectSupportDataStatusFromJSON,
    ProjectSupportDataStatusFromJSONTyped,
    ProjectSupportDataStatusToJSON,
} from './ProjectSupportDataStatus';
import type { Question } from './Question';
import {
    QuestionFromJSON,
    QuestionFromJSONTyped,
    QuestionToJSON,
} from './Question';

/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    projectType: ProjectProjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    language: ProjectLanguageEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Project
     */
    tags?: Array<string> | null;
    /**
     * 
     * @type {ProjectStatusObject}
     * @memberof Project
     */
    status: ProjectStatusObject;
    /**
     * echo timestamp format, e.g 1680613256
     * @type {number}
     * @memberof Project
     */
    createdTime: number;
    /**
     * echo timestamp format, e.g 1680613256
     * @type {number}
     * @memberof Project
     */
    lastModifiedTime?: number | null;
    /**
     * 
     * @type {ProjectSupportDataStatus}
     * @memberof Project
     */
    supportDataStatus?: ProjectSupportDataStatus;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    minimalCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    similarityLevel?: number | null;
    /**
     * 
     * @type {ProjectListMetadata}
     * @memberof Project
     */
    metadata?: ProjectListMetadata;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    datafilePath?: string;
    /**
     * 
     * @type {Array<ColumnRecognizeResult>}
     * @memberof Project
     */
    columns?: Array<ColumnRecognizeResult>;
    /**
     * 
     * @type {Array<Question>}
     * @memberof Project
     */
    questions?: Array<Question>;
}


/**
 * @export
 */
export const ProjectProjectTypeEnum = {
    Sentence: 'SENTENCE',
    Phrase: 'PHRASE'
} as const;
export type ProjectProjectTypeEnum = typeof ProjectProjectTypeEnum[keyof typeof ProjectProjectTypeEnum];

/**
 * @export
 */
export const ProjectLanguageEnum = {
    English: 'ENGLISH',
    Chinese: 'CHINESE'
} as const;
export type ProjectLanguageEnum = typeof ProjectLanguageEnum[keyof typeof ProjectLanguageEnum];


/**
 * Check if a given object implements the Project interface.
 */
export function instanceOfProject(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "projectType" in value;
    isInstance = isInstance && "language" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "createdTime" in value;

    return isInstance;
}

export function ProjectFromJSON(json: any): Project {
    return ProjectFromJSONTyped(json, false);
}

export function ProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): Project {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'projectType': json['projectType'],
        'language': json['language'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'status': ProjectStatusObjectFromJSON(json['status']),
        'createdTime': json['createdTime'],
        'lastModifiedTime': !exists(json, 'lastModifiedTime') ? undefined : json['lastModifiedTime'],
        'supportDataStatus': !exists(json, 'supportDataStatus') ? undefined : ProjectSupportDataStatusFromJSON(json['supportDataStatus']),
        'minimalCount': !exists(json, 'minimalCount') ? undefined : json['minimalCount'],
        'similarityLevel': !exists(json, 'similarityLevel') ? undefined : json['similarityLevel'],
        'metadata': !exists(json, 'metadata') ? undefined : ProjectListMetadataFromJSON(json['metadata']),
        'datafilePath': !exists(json, 'datafilePath') ? undefined : json['datafilePath'],
        'columns': !exists(json, 'columns') ? undefined : ((json['columns'] as Array<any>).map(ColumnRecognizeResultFromJSON)),
        'questions': !exists(json, 'questions') ? undefined : ((json['questions'] as Array<any>).map(QuestionFromJSON)),
    };
}

export function ProjectToJSON(value?: Project | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'projectType': value.projectType,
        'language': value.language,
        'tags': value.tags,
        'status': ProjectStatusObjectToJSON(value.status),
        'createdTime': value.createdTime,
        'lastModifiedTime': value.lastModifiedTime,
        'supportDataStatus': ProjectSupportDataStatusToJSON(value.supportDataStatus),
        'minimalCount': value.minimalCount,
        'similarityLevel': value.similarityLevel,
        'metadata': ProjectListMetadataToJSON(value.metadata),
        'datafilePath': value.datafilePath,
        'columns': value.columns === undefined ? undefined : ((value.columns as Array<any>).map(ColumnRecognizeResultToJSON)),
        'questions': value.questions === undefined ? undefined : ((value.questions as Array<any>).map(QuestionToJSON)),
    };
}

