/* tslint:disable */
/* eslint-disable */
/**
 * Parallels Data
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProjectStatus = {
    Initiated: 'INITIATED',
    DataImported: 'DATA_IMPORTED',
    DatafileInvalid: 'DATAFILE_INVALID',
    ColumnsRecognised: 'COLUMNS_RECOGNISED',
    ColumnsConfirmed: 'COLUMNS_CONFIRMED',
    ReferenceUploaded: 'REFERENCE_UPLOADED',
    AutocodingInProgress: 'AUTOCODING_IN_PROGRESS',
    AutocodingCompleted: 'AUTOCODING_COMPLETED'
} as const;
export type ProjectStatus = typeof ProjectStatus[keyof typeof ProjectStatus];


export function ProjectStatusFromJSON(json: any): ProjectStatus {
    return ProjectStatusFromJSONTyped(json, false);
}

export function ProjectStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectStatus {
    return json as ProjectStatus;
}

export function ProjectStatusToJSON(value?: ProjectStatus | null): any {
    return value as any;
}

