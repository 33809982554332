/* tslint:disable */
/* eslint-disable */
/**
 * Parallels Data
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {Configuration} from './configuration'
import type {AxiosPromise, AxiosInstance, AxiosRequestConfig} from 'axios'
import globalAxios from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from './common'
import type {RequestArgs} from './base'
// @ts-ignore
import {BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError} from './base'

/**
 *
 * @export
 * @interface AuthRefreshPost200Response
 */
export interface AuthRefreshPost200Response {
    /**
     * Refreshed access token
     * @type {string}
     * @memberof AuthRefreshPost200Response
     */
    accessToken: string
    /**
     * Access token expiration in seconds
     * @type {number}
     * @memberof AuthRefreshPost200Response
     */
    expiresInSeconds: number
}
/**
 *
 * @export
 * @interface AuthTokenPost200Response
 */
export interface AuthTokenPost200Response {
    /**
     * Access token
     * @type {string}
     * @memberof AuthTokenPost200Response
     */
    accessToken: string
    /**
     * Refresh token used to refresh access token once it expires
     * @type {string}
     * @memberof AuthTokenPost200Response
     */
    refreshToken: string
    /**
     * Access token expiration in seconds
     * @type {number}
     * @memberof AuthTokenPost200Response
     */
    expiresInSeconds: number
}
/**
 *
 * @export
 * @interface ColumnRecognizeResult
 */
export interface ColumnRecognizeResult {
    /**
     *
     * @type {string}
     * @memberof ColumnRecognizeResult
     */
    name: string
    /**
     *
     * @type {ColumnType}
     * @memberof ColumnRecognizeResult
     */
    columnType?: ColumnType
    /**
     *
     * @type {boolean}
     * @memberof ColumnRecognizeResult
     */
    analysis?: boolean
}

/**
 *
 * @export
 * @enum {string}
 */

export const ColumnType = {
    Number: 'number',
    String: 'string',
    Date: 'date',
    Enum: 'enum',
    Boolean: 'boolean',
    Empty: 'empty',
    Other: 'other',
} as const

export type ColumnType = (typeof ColumnType)[keyof typeof ColumnType]

/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * Error code
     * @type {string}
     * @memberof ModelError
     */
    code: string
    /**
     * Description of error
     * @type {Array<string>}
     * @memberof ModelError
     */
    message: Array<string>
}
/**
 *
 * @export
 * @interface Project
 */
export interface Project {
    /**
     *
     * @type {string}
     * @memberof Project
     */
    id: string
    /**
     *
     * @type {string}
     * @memberof Project
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof Project
     */
    projectType: ProjectProjectTypeEnum
    /**
     *
     * @type {string}
     * @memberof Project
     */
    language: ProjectLanguageEnum
    /**
     *
     * @type {Array<string>}
     * @memberof Project
     */
    tags?: Array<string> | null
    /**
     *
     * @type {ProjectStatus}
     * @memberof Project
     */
    status: ProjectStatus
    /**
     * echo timestamp format, e.g 1680613256
     * @type {number}
     * @memberof Project
     */
    createTime: number
    /**
     * echo timestamp format, e.g 1680613256
     * @type {number}
     * @memberof Project
     */
    lastModifiedTime?: number | null
    /**
     *
     * @type {ProjectSupportDataStatus}
     * @memberof Project
     */
    supportDataStatus?: ProjectSupportDataStatus
    /**
     *
     * @type {number}
     * @memberof Project
     */
    minimalCount?: number | null
    /**
     *
     * @type {number}
     * @memberof Project
     */
    similarityLevel?: number | null
    /**
     *
     * @type {Array<ColumnRecognizeResult>}
     * @memberof Project
     */
    columns?: Array<ColumnRecognizeResult>
}

export const ProjectProjectTypeEnum = {
    Sentence: 'SENTENCE',
    Phrase: 'PHRASE',
} as const

export type ProjectProjectTypeEnum = (typeof ProjectProjectTypeEnum)[keyof typeof ProjectProjectTypeEnum]
export const ProjectLanguageEnum = {
    English: 'ENGLISH',
    Chinese: 'CHINESE',
} as const

export type ProjectLanguageEnum = (typeof ProjectLanguageEnum)[keyof typeof ProjectLanguageEnum]

/**
 *
 * @export
 * @interface ProjectRequestBody
 */
export interface ProjectRequestBody {
    /**
     *
     * @type {string}
     * @memberof ProjectRequestBody
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof ProjectRequestBody
     */
    projectType: ProjectRequestBodyProjectTypeEnum
    /**
     *
     * @type {string}
     * @memberof ProjectRequestBody
     */
    language: ProjectRequestBodyLanguageEnum
    /**
     *
     * @type {Array<string>}
     * @memberof ProjectRequestBody
     */
    tags?: Array<string> | null
    /**
     *
     * @type {number}
     * @memberof ProjectRequestBody
     */
    minimalCount?: number | null
    /**
     *
     * @type {number}
     * @memberof ProjectRequestBody
     */
    similarityLevel?: number | null
}

export const ProjectRequestBodyProjectTypeEnum = {
    Sentence: 'SENTENCE',
    Phrase: 'PHRASE',
} as const

export type ProjectRequestBodyProjectTypeEnum =
    (typeof ProjectRequestBodyProjectTypeEnum)[keyof typeof ProjectRequestBodyProjectTypeEnum]
export const ProjectRequestBodyLanguageEnum = {
    English: 'ENGLISH',
    Chinese: 'CHINESE',
} as const

export type ProjectRequestBodyLanguageEnum =
    (typeof ProjectRequestBodyLanguageEnum)[keyof typeof ProjectRequestBodyLanguageEnum]

/**
 *
 * @export
 * @enum {string}
 */

export const ProjectStatus = {
    Initiated: 'INITIATED',
    DataImported: 'DATA_IMPORTED',
    DatafileInvalid: 'DATAFILE_INVALID',
    DatafileSet: 'DATAFILE_SET',
    ReferenceUploaded: 'REFERENCE_UPLOADED',
    ParameterSet: 'PARAMETER_SET',
} as const

export type ProjectStatus = (typeof ProjectStatus)[keyof typeof ProjectStatus]

/**
 *
 * @export
 * @interface ProjectSupportDataStatus
 */
export interface ProjectSupportDataStatus {
    /**
     *
     * @type {boolean}
     * @memberof ProjectSupportDataStatus
     */
    dataFileUploaded: boolean
    /**
     *
     * @type {boolean}
     * @memberof ProjectSupportDataStatus
     */
    codebookCreated: boolean
}
/**
 *
 * @export
 * @interface Term
 */
export interface Term {
    /**
     *
     * @type {string}
     * @memberof Term
     */
    name: string
    /**
     *
     * @type {number}
     * @memberof Term
     */
    count: number
}
/**
 *
 * @export
 * @interface TopicLabel
 */
export interface TopicLabel {
    /**
     *
     * @type {string}
     * @memberof TopicLabel
     */
    name: string
    /**
     *
     * @type {number}
     * @memberof TopicLabel
     */
    count: number
    /**
     *
     * @type {Array<Term>}
     * @memberof TopicLabel
     */
    terms: Array<Term>
}
/**
 *
 * @export
 * @interface UploadUrl
 */
export interface UploadUrl {
    /**
     *
     * @type {string}
     * @memberof UploadUrl
     */
    url: string
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get tokens from refresh token
         * @summary Refresh tokens
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRefreshPost: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/refresh`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = {method: 'POST', ...baseOptions, ...options}
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * Get tokens from authorisation code
         * @summary Get tokens
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenPost: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/token`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = {method: 'POST', ...baseOptions, ...options}
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
    }
}

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * Get tokens from refresh token
         * @summary Refresh tokens
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authRefreshPost(
            body?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthRefreshPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authRefreshPost(body, options)
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
        },
        /**
         * Get tokens from authorisation code
         * @summary Get tokens
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authTokenPost(
            body?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthTokenPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authTokenPost(body, options)
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
        },
    }
}

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * Get tokens from refresh token
         * @summary Refresh tokens
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRefreshPost(body?: string, options?: any): AxiosPromise<AuthRefreshPost200Response> {
            return localVarFp.authRefreshPost(body, options).then((request) => request(axios, basePath))
        },
        /**
         * Get tokens from authorisation code
         * @summary Get tokens
         * @param {string} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenPost(body?: string, options?: any): AxiosPromise<AuthTokenPost200Response> {
            return localVarFp.authTokenPost(body, options).then((request) => request(axios, basePath))
        },
    }
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * Get tokens from refresh token
     * @summary Refresh tokens
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authRefreshPost(body?: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration)
            .authRefreshPost(body, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * Get tokens from authorisation code
     * @summary Get tokens
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authTokenPost(body?: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration)
            .authTokenPost(body, options)
            .then((request) => request(this.axios, this.basePath))
    }
}

/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get upload link of codebook for a project
         * @param {number} projectId ID of the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectIdCodebookUploadUrlGet: async (
            projectId: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectProjectIdCodebookUploadUrlGet', 'projectId', projectId)
            const localVarPath = `/project/{projectId}/codebook/upload-url`.replace(
                `{${'projectId'}}`,
                encodeURIComponent(String(projectId)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options}
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         *
         * @summary update project setting
         * @param {number} projectId ID of the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectIdMachineCodingPost: async (
            projectId: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectProjectIdMachineCodingPost', 'projectId', projectId)
            const localVarPath = `/project/{projectId}/machineCoding`.replace(
                `{${'projectId'}}`,
                encodeURIComponent(String(projectId)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = {method: 'POST', ...baseOptions, ...options}
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         *
         * @summary Get my project list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options}
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * Create a project with basic setting
         * @summary Create a project
         * @param {ProjectRequestBody} [projectRequestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsPost: async (
            projectRequestBody?: ProjectRequestBody,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/projects`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = {method: 'POST', ...baseOptions, ...options}
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}
            localVarRequestOptions.data = serializeDataIfNeeded(
                projectRequestBody,
                localVarRequestOptions,
                configuration,
            )

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         *
         * @summary Update columns
         * @param {number} projectId ID of the project
         * @param {Array<ColumnRecognizeResult>} columnRecognizeResult project detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsProjectIdDataFileColumnsPut: async (
            projectId: number,
            columnRecognizeResult: Array<ColumnRecognizeResult>,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectsProjectIdDataFileColumnsPut', 'projectId', projectId)
            // verify required parameter 'columnRecognizeResult' is not null or undefined
            assertParamExists('projectsProjectIdDataFileColumnsPut', 'columnRecognizeResult', columnRecognizeResult)
            const localVarPath = `/projects/{projectId}/data-file/columns`.replace(
                `{${'projectId'}}`,
                encodeURIComponent(String(projectId)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = {method: 'PUT', ...baseOptions, ...options}
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}
            localVarRequestOptions.data = serializeDataIfNeeded(
                columnRecognizeResult,
                localVarRequestOptions,
                configuration,
            )

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         *
         * @summary Get data file upload url
         * @param {number} projectId ID of the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsProjectIdDataFileUploadUrlGet: async (
            projectId: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectsProjectIdDataFileUploadUrlGet', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/data-file/upload-url`.replace(
                `{${'projectId'}}`,
                encodeURIComponent(String(projectId)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options}
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         *
         * @summary Get individual project information
         * @param {number} projectId ID of the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsProjectIdGet: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectsProjectIdGet', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}`.replace(
                `{${'projectId'}}`,
                encodeURIComponent(String(projectId)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options}
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         *
         * @summary update project setting
         * @param {number} projectId ID of the project
         * @param {ProjectRequestBody} projectRequestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsProjectIdPut: async (
            projectId: number,
            projectRequestBody: ProjectRequestBody,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectsProjectIdPut', 'projectId', projectId)
            // verify required parameter 'projectRequestBody' is not null or undefined
            assertParamExists('projectsProjectIdPut', 'projectRequestBody', projectRequestBody)
            const localVarPath = `/projects/{projectId}`.replace(
                `{${'projectId'}}`,
                encodeURIComponent(String(projectId)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = {method: 'PUT', ...baseOptions, ...options}
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            localVarHeaderParameter['Content-Type'] = 'application/json'

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers}
            localVarRequestOptions.data = serializeDataIfNeeded(
                projectRequestBody,
                localVarRequestOptions,
                configuration,
            )

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
    }
}

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Get upload link of codebook for a project
         * @param {number} projectId ID of the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectProjectIdCodebookUploadUrlGet(
            projectId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadUrl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectProjectIdCodebookUploadUrlGet(
                projectId,
                options,
            )
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
        },
        /**
         *
         * @summary update project setting
         * @param {number} projectId ID of the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectProjectIdMachineCodingPost(
            projectId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectProjectIdMachineCodingPost(
                projectId,
                options,
            )
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
        },
        /**
         *
         * @summary Get my project list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsGet(
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsGet(options)
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
        },
        /**
         * Create a project with basic setting
         * @summary Create a project
         * @param {ProjectRequestBody} [projectRequestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsPost(
            projectRequestBody?: ProjectRequestBody,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsPost(projectRequestBody, options)
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
        },
        /**
         *
         * @summary Update columns
         * @param {number} projectId ID of the project
         * @param {Array<ColumnRecognizeResult>} columnRecognizeResult project detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsProjectIdDataFileColumnsPut(
            projectId: number,
            columnRecognizeResult: Array<ColumnRecognizeResult>,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsProjectIdDataFileColumnsPut(
                projectId,
                columnRecognizeResult,
                options,
            )
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
        },
        /**
         *
         * @summary Get data file upload url
         * @param {number} projectId ID of the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsProjectIdDataFileUploadUrlGet(
            projectId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadUrl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsProjectIdDataFileUploadUrlGet(
                projectId,
                options,
            )
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
        },
        /**
         *
         * @summary Get individual project information
         * @param {number} projectId ID of the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsProjectIdGet(
            projectId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsProjectIdGet(projectId, options)
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
        },
        /**
         *
         * @summary update project setting
         * @param {number} projectId ID of the project
         * @param {ProjectRequestBody} projectRequestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsProjectIdPut(
            projectId: number,
            projectRequestBody: ProjectRequestBody,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsProjectIdPut(
                projectId,
                projectRequestBody,
                options,
            )
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
        },
    }
}

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsApiFp(configuration)
    return {
        /**
         *
         * @summary Get upload link of codebook for a project
         * @param {number} projectId ID of the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectIdCodebookUploadUrlGet(projectId: number, options?: any): AxiosPromise<UploadUrl> {
            return localVarFp
                .projectProjectIdCodebookUploadUrlGet(projectId, options)
                .then((request) => request(axios, basePath))
        },
        /**
         *
         * @summary update project setting
         * @param {number} projectId ID of the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectIdMachineCodingPost(projectId: number, options?: any): AxiosPromise<Project> {
            return localVarFp
                .projectProjectIdMachineCodingPost(projectId, options)
                .then((request) => request(axios, basePath))
        },
        /**
         *
         * @summary Get my project list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsGet(options?: any): AxiosPromise<Array<Project>> {
            return localVarFp.projectsGet(options).then((request) => request(axios, basePath))
        },
        /**
         * Create a project with basic setting
         * @summary Create a project
         * @param {ProjectRequestBody} [projectRequestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsPost(projectRequestBody?: ProjectRequestBody, options?: any): AxiosPromise<Project> {
            return localVarFp.projectsPost(projectRequestBody, options).then((request) => request(axios, basePath))
        },
        /**
         *
         * @summary Update columns
         * @param {number} projectId ID of the project
         * @param {Array<ColumnRecognizeResult>} columnRecognizeResult project detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsProjectIdDataFileColumnsPut(
            projectId: number,
            columnRecognizeResult: Array<ColumnRecognizeResult>,
            options?: any,
        ): AxiosPromise<Project> {
            return localVarFp
                .projectsProjectIdDataFileColumnsPut(projectId, columnRecognizeResult, options)
                .then((request) => request(axios, basePath))
        },
        /**
         *
         * @summary Get data file upload url
         * @param {number} projectId ID of the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsProjectIdDataFileUploadUrlGet(projectId: number, options?: any): AxiosPromise<UploadUrl> {
            return localVarFp
                .projectsProjectIdDataFileUploadUrlGet(projectId, options)
                .then((request) => request(axios, basePath))
        },
        /**
         *
         * @summary Get individual project information
         * @param {number} projectId ID of the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsProjectIdGet(projectId: number, options?: any): AxiosPromise<Project> {
            return localVarFp.projectsProjectIdGet(projectId, options).then((request) => request(axios, basePath))
        },
        /**
         *
         * @summary update project setting
         * @param {number} projectId ID of the project
         * @param {ProjectRequestBody} projectRequestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsProjectIdPut(
            projectId: number,
            projectRequestBody: ProjectRequestBody,
            options?: any,
        ): AxiosPromise<Project> {
            return localVarFp
                .projectsProjectIdPut(projectId, projectRequestBody, options)
                .then((request) => request(axios, basePath))
        },
    }
}

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     *
     * @summary Get upload link of codebook for a project
     * @param {number} projectId ID of the project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectProjectIdCodebookUploadUrlGet(projectId: number, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration)
            .projectProjectIdCodebookUploadUrlGet(projectId, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     *
     * @summary update project setting
     * @param {number} projectId ID of the project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectProjectIdMachineCodingPost(projectId: number, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration)
            .projectProjectIdMachineCodingPost(projectId, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     *
     * @summary Get my project list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsGet(options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration)
            .projectsGet(options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * Create a project with basic setting
     * @summary Create a project
     * @param {ProjectRequestBody} [projectRequestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsPost(projectRequestBody?: ProjectRequestBody, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration)
            .projectsPost(projectRequestBody, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     *
     * @summary Update columns
     * @param {number} projectId ID of the project
     * @param {Array<ColumnRecognizeResult>} columnRecognizeResult project detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsProjectIdDataFileColumnsPut(
        projectId: number,
        columnRecognizeResult: Array<ColumnRecognizeResult>,
        options?: AxiosRequestConfig,
    ) {
        return ProjectsApiFp(this.configuration)
            .projectsProjectIdDataFileColumnsPut(projectId, columnRecognizeResult, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     *
     * @summary Get data file upload url
     * @param {number} projectId ID of the project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsProjectIdDataFileUploadUrlGet(projectId: number, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration)
            .projectsProjectIdDataFileUploadUrlGet(projectId, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     *
     * @summary Get individual project information
     * @param {number} projectId ID of the project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsProjectIdGet(projectId: number, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration)
            .projectsProjectIdGet(projectId, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     *
     * @summary update project setting
     * @param {number} projectId ID of the project
     * @param {ProjectRequestBody} projectRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsProjectIdPut(
        projectId: number,
        projectRequestBody: ProjectRequestBody,
        options?: AxiosRequestConfig,
    ) {
        return ProjectsApiFp(this.configuration)
            .projectsProjectIdPut(projectId, projectRequestBody, options)
            .then((request) => request(this.axios, this.basePath))
    }
}
