import {getProjectApi} from '../projectDetail/projectApi'
import {delay, putFileToS3PresignedUrl} from '../../utils/commonUtils'
import axios, {AxiosResponse} from 'axios'
import {API_BASE_URL} from '../../constant'
import {getAuthHeader} from '../../utils/apiClient'
import {Project, FileExchangeUrl} from '../../api'

export async function uploadCodeBookApi(projectId: string, file: File) {
    const response = await axios.get(`${API_BASE_URL}/projects/${projectId}/codebook/upload-url`, getAuthHeader())
    const data: FileExchangeUrl = response.data
    const url = data.url
    await putFileToS3PresignedUrl(file, url)
    return pollProjectStatus(projectId, 'codebook')
}

export async function uploadDatabaseApi(projectId: string, file: File) {
    const response = await axios.get(`${API_BASE_URL}/projects/${projectId}/database/upload-url`, getAuthHeader())
    const data: FileExchangeUrl = response.data
    const url = data.url
    await putFileToS3PresignedUrl(file, url)
    return pollProjectStatus(projectId, 'database')
}

export async function uploadSampleDataApi(projectId: string, file: File) {
    const response = await axios.get(`${API_BASE_URL}/projects/${projectId}/sample-data/upload-url`, getAuthHeader())
    const data: FileExchangeUrl = response.data
    const url = data.url
    await putFileToS3PresignedUrl(file, url)
    return pollProjectStatus(projectId, 'sampleData')
}

async function pollProjectStatus(projectId: string, fieldName: string): Promise<AxiosResponse<any>> {
    const projectResponse: AxiosResponse<any> = await getProjectApi(projectId)
    const project: Project = projectResponse.data

    if (fieldName === 'codebook' && project.supportDataStatus?.codeBookUploaded) {
        return projectResponse
    } else if (fieldName === 'database' && project.supportDataStatus?.databaseUploaded) {
        return projectResponse
    } else if (fieldName === 'sampleData' && project.supportDataStatus?.databaseUploaded) {
        return projectResponse
    } else {
        await delay(2000)
        return pollProjectStatus(projectId, fieldName)
    }
}

export async function confirmReferenceData(projectId: string) {
    const response = await axios.post(
        `${API_BASE_URL}/projects/${projectId}/confirm-reference-upload`,
        {},
        getAuthHeader(),
    )
    return response.data
}
