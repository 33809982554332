/* tslint:disable */
/* eslint-disable */
/**
 * Parallels Data
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AutocodingResultInner
 */
export interface AutocodingResultInner {
    /**
     * 
     * @type {string}
     * @memberof AutocodingResultInner
     */
    verbatim?: string;
    /**
     * 
     * @type {number}
     * @memberof AutocodingResultInner
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof AutocodingResultInner
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof AutocodingResultInner
     */
    codeName?: string;
    /**
     * 
     * @type {number}
     * @memberof AutocodingResultInner
     */
    confidenceLevel?: number;
    /**
     * 
     * @type {number}
     * @memberof AutocodingResultInner
     */
    type?: number;
    /**
     * 
     * @type {string}
     * @memberof AutocodingResultInner
     */
    codeType?: string;
}

/**
 * Check if a given object implements the AutocodingResultInner interface.
 */
export function instanceOfAutocodingResultInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AutocodingResultInnerFromJSON(json: any): AutocodingResultInner {
    return AutocodingResultInnerFromJSONTyped(json, false);
}

export function AutocodingResultInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutocodingResultInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'verbatim': !exists(json, 'Verbatim') ? undefined : json['Verbatim'],
        'count': !exists(json, 'Count') ? undefined : json['Count'],
        'code': !exists(json, 'Code') ? undefined : json['Code'],
        'codeName': !exists(json, 'Code Name') ? undefined : json['Code Name'],
        'confidenceLevel': !exists(json, 'Confidence Level') ? undefined : json['Confidence Level'],
        'type': !exists(json, 'Type') ? undefined : json['Type'],
        'codeType': !exists(json, 'Code Type') ? undefined : json['Code Type'],
    };
}

export function AutocodingResultInnerToJSON(value?: AutocodingResultInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Verbatim': value.verbatim,
        'Count': value.count,
        'Code': value.code,
        'Code Name': value.codeName,
        'Confidence Level': value.confidenceLevel,
        'Type': value.type,
        'Code Type': value.codeType,
    };
}

