import React, {FC, useState} from 'react'
import {Upload, Button, message} from 'antd'
import {UploadOutlined, CheckCircleOutlined} from '@ant-design/icons'
import {RcFile} from 'antd/lib/upload'
import {AppDispatch, RootState} from '../../app/store'
import {useDispatch, useSelector} from 'react-redux'
import {
    confirmReferenceDataAsync,
    uploadCodeBookAsync,
    uploadDatabaseAsync,
    uploadSampleDataAsync,
} from '../projectCreate/projectSlice'
import './ReferenceUpload.css'
import {ProjectProjectTypeEnum} from '../../api/api'
// 2. Create a file upload component
const ReferenceUpload: FC = () => {
    const dispatch: AppDispatch = useDispatch()
    const currentProject = useSelector((state: RootState) => state.project.project)
    const [needToReuploadCodeBook, setNeedToReuploadCodeBook] = useState(false)
    const [needToReuploadDatabase, setNeedToReuploadDatabase] = useState(false)
    const [needToReuploadSampleData, setNeedToReuploadSampleData] = useState(false)

    const beforeUpload = () => {
        // Add any validation or processing logic here
        return false // Return false to prevent auto uploading
    }

    const onCodeBookDrop = (info: any) => {
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`)
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`)
        }
        setNeedToReuploadCodeBook(false)
        dispatch(uploadCodeBookAsync({projectId: currentProject?.id ? currentProject.id : '', file: info.file}))
    }

    const userTriggerReupload = (file: string) => {
        if (file === 'codebook') {
            setNeedToReuploadCodeBook(true)
        } else if (file === 'database') {
            setNeedToReuploadDatabase(true)
        } else if (file === 'sampleData') {
            setNeedToReuploadSampleData(true)
        }
    }

    const onDataBaseDrop = (info: any) => {
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`)
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`)
        }
        setNeedToReuploadDatabase(false)
        dispatch(uploadDatabaseAsync({projectId: currentProject?.id ? currentProject.id : '', file: info.file}))
    }

    const onSampleDataDrop = (info: any) => {
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`)
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`)
        }
        setNeedToReuploadSampleData(false)
        dispatch(uploadSampleDataAsync({projectId: currentProject?.id ? currentProject.id : '', file: info.file}))
    }

    const confirmReferenceData = () => {
        if (!currentProject) {
            return
        }
        dispatch(confirmReferenceDataAsync(currentProject.id))
    }

    return (
        <div>
            <h1>Reference Data Upload</h1>
            <div className='reference-data-upload-section'>
                {!currentProject?.supportDataStatus?.codeBookUploaded || needToReuploadCodeBook ? (
                    <div className='upload-unit'>
                        <Upload.Dragger
                            name='file'
                            multiple={true}
                            beforeUpload={beforeUpload}
                            onChange={onCodeBookDrop}
                            showUploadList={false}
                        >
                            <p className='ant-upload-drag-icon'>
                                <UploadOutlined />
                            </p>
                            <p className='ant-upload-text'>Click or drag file to this area to upload Code Book</p>
                            <p className='ant-upload-hint'>Support single file upload.</p>
                        </Upload.Dragger>
                        <input
                            id='fileInput'
                            type='file'
                            multiple
                            style={{display: 'none'}}
                            onChange={(e) => {
                                // Handle file selection from file explorer
                                console.log(e.target.files)
                            }}
                        />
                    </div>
                ) : (
                    <div className='success-container'>
                        <CheckCircleOutlined style={{fontSize: '48px', color: 'green'}} />
                        <div className='success-message'>Code Book Upload successfully</div>
                        <Button type='primary' onClick={() => userTriggerReupload('codebook')}>
                            ReUpload Code Book
                        </Button>
                    </div>
                )}

                {!currentProject?.supportDataStatus?.databaseUploaded || needToReuploadDatabase ? (
                    <div className='upload-unit'>
                        <Upload.Dragger
                            name='file'
                            multiple={true}
                            beforeUpload={beforeUpload}
                            onChange={onDataBaseDrop}
                            showUploadList={false}
                        >
                            <p className='ant-upload-drag-icon'>
                                <UploadOutlined />
                            </p>
                            <p className='ant-upload-text'>Click or drag file to this area to upload DataBase</p>
                            <p className='ant-upload-hint'>Support single file upload.</p>
                        </Upload.Dragger>
                        <input
                            id='fileInput'
                            type='file'
                            multiple
                            style={{display: 'none'}}
                            onChange={(e) => {
                                // Handle file selection from file explorer
                                console.log(e.target.files)
                            }}
                        />
                    </div>
                ) : (
                    <div className='success-container'>
                        <CheckCircleOutlined style={{fontSize: '48px', color: 'green'}} />
                        <div className='success-message'>Database Upload successfully</div>
                        <Button type='primary' onClick={() => userTriggerReupload('database')}>
                            Reupload Database
                        </Button>
                    </div>
                )}

                {currentProject?.projectType === ProjectProjectTypeEnum.Sentence &&
                    (!currentProject?.supportDataStatus?.exampleDataUploaded || needToReuploadSampleData ? (
                        <div className='upload-unit'>
                            <Upload.Dragger
                                name='file'
                                multiple={true}
                                beforeUpload={beforeUpload}
                                onChange={onSampleDataDrop}
                                showUploadList={false}
                            >
                                <p className='ant-upload-drag-icon'>
                                    <UploadOutlined />
                                </p>
                                <p className='ant-upload-text'>
                                    Click or drag file to this area to upload Sample Data.
                                </p>
                                <p className='ant-upload-hint'>Support single file upload.</p>
                            </Upload.Dragger>
                            <input
                                id='fileInput'
                                type='file'
                                multiple
                                style={{display: 'none'}}
                                onChange={(e) => {
                                    // Handle file selection from file explorer
                                    console.log(e.target.files)
                                }}
                            />
                        </div>
                    ) : (
                        <div className='success-container'>
                            <CheckCircleOutlined style={{fontSize: '48px', color: 'green'}} />
                            <div className='success-message'>Sample Data Upload successfully</div>
                            <Button type='primary' onClick={() => userTriggerReupload('sampleData')}>
                                Reupload Sample Data
                            </Button>
                        </div>
                    ))}
            </div>

            <div className='confirm-reference'>
                <Button type='primary' onClick={() => confirmReferenceData()}>
                    {' '}
                    Confirm Reference Data{' '}
                </Button>
            </div>
        </div>
    )
}

export default ReferenceUpload
